









































import DateTimePicker from './DateTimePicker.vue';
import { computed, defineComponent, reactive, ref, watch } from '@/plugins/composition';
import { formatDateTime } from '@/utils';

export default defineComponent({
  components: { DateTimePicker },
  name: 'DateTimeRangePicker',

  props: {
    value: {
      type: Object,
      required: true,
    },
    interval: {
      type: [Number],
      required: false,
      default: 1,
    },
  },

  setup(props, { emit }) {
    const dialog = ref(false);
    const valueObject = reactive({
      start: null,
      end: null,
    } as {
      start: Date | string | null;
      end: Date | string | null;
    });

    const startDate = computed({
      get: () => props.value.start,
      set: (value: string) => (valueObject.start = value),
    });
    const endDate = computed({
      get: () => props.value.end,
      set: (value: string) => (valueObject.end = value),
    });

    const formattedRange = computed(() => {
      const start = formatDateTime(startDate.value);
      const end = formatDateTime(endDate.value);
      return `From: ${start} \nTo: ${end}`;
    });

    watch(valueObject, () => {
      emit('input', valueObject);
    });

    return {
      startDate,
      endDate,
      formattedRange,
      dialog,
    };
  },
});
