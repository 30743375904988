






























































































import CreateBookingDialog from '../../../components/CreateBookingDialog.vue';
import CreateScheduleDialog from '../../../components/CreateScheduleDialog.vue';
import PractitionerSelectInput from '@/components/inputs/PractitionerSelectInput.vue';
import { Booking, Practitioner, Schedule } from '@/models';
import { CalendarEvent, CalendarTimestamp } from 'vuetify';
import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';
import { confirmation } from '@/domains/shared/components/confirm-dialog/confirm';
import { formatISO } from 'date-fns';
import { getTimeFromISO, getTimesFromISO } from '@/utils';
import { intervalStyle, roundTime, showLabel, toTime } from '../composables/dailyCalendar';
import { routeNames } from '@/router';
import { storage } from '@/main';

type Cal = {
  timeToY: (time: number | string | { hour: number; minute: number }, clamp?: boolean) => number | false;
  times: {
    now: number | string;
  };
  next: () => void;
  prev: () => void;
};
export default defineComponent({
  components: { CreateBookingDialog, CreateScheduleDialog, PractitionerSelectInput },
  name: 'DailyScheduleCalendar',
  setup() {
    const value = ref('');
    const calendar = ref<Cal>({} as Cal);
    const practitioner = ref<Practitioner>(new Practitioner());
    const selectedPractitioner = ref<Practitioner>(new Practitioner());

    const bookings = ref<Booking[]>([]);
    const schedules = ref<Schedule[]>([]);

    const events = computed(() =>
      tempEvent.value
        ? [...bookings.value, ...schedules.value, tempEvent.value]
        : [...bookings.value, ...schedules.value]
    );

    const tempEvent = ref<Booking>();
    const editingEvent = ref<Booking | null>();

    const addingBooking = ref(false);
    const addingSchedule = ref(false);
    const addingScheduleDate = ref<null | string>(null);
    const editingSchedule = ref<Schedule | null>();

    const addSiteVisit = (day: CalendarTimestamp): void => {
      addingScheduleDate.value = day.date;
      addingSchedule.value = true;
    };

    const dragged = ref(false);

    const dragStart = ref(null);
    const dragEvent = ref<CalendarEvent | null>(null);
    const createEvent = ref<CalendarEvent | null>();
    const createStart = ref<number | null>(0);
    const extendOriginal = ref<Booking | null>(null); // for cancel drag
    const dragTime = ref<number | null>(0);

    const range = reactive({
      start: '',
      end: '',
    });

    const resetTempObjects = async (fetch = false): Promise<void> => {
      const reset = () => {
        tempEvent.value = undefined;
        dragEvent.value = null;
        editingEvent.value = undefined;
      };

      if ((fetch && dragEvent.value) || editingEvent.value) {
        reset();
        await fetchBookings();
      }
      reset();
    };

    const startDrag = ({
      event,
      timed,
      nativeEvent,
    }: {
      event: Booking | Schedule;
      timed: boolean;
      nativeEvent: MouseEvent;
    }): void => {
      if (event && timed && nativeEvent.button === 0) {
        dragEvent.value = event;
        dragTime.value = null;
        extendOriginal.value = null;
      } else if (event && !timed && nativeEvent.button === 0) {
        dragEvent.value = event;
        addingSchedule.value = true;
        editingSchedule.value = event as Schedule;
      }
    };

    const updateRange = async ({ start, end }: { start: CalendarTimestamp; end: CalendarTimestamp }) => {
      range.start = formatISO(new Date(start.date));
      range.end = formatISO(new Date(end.date));
      if (selectedPractitioner.value.exists()) {
        await fetchBookings();
      }
    };

    const getEventColor = (event: Booking): string => {
      const isDragged = extendOriginal.value === event || dragEvent.value === event;
      return event.colour(isDragged);
    };

    const setPractitioner = async (): Promise<void> => {
      const user = await storage.auth.practitioner();
      if (user) {
        practitioner.value = user;
        selectedPractitioner.value = user;
      }
    };

    const fetchBookings = async (): Promise<void> => {
      const [_bookings, _schedules] = await Promise.all([
        selectedPractitioner.value.bookings().where('starts_between', [range.start, range.end]).getAll(),
        selectedPractitioner.value.schedules().where('starts_between', [range.start, range.end]).getAll(),
      ]);

      bookings.value = _bookings;
      schedules.value = _schedules;
    };

    function extendBottom(event: Booking) {
      editingEvent.value = event;
      createStart.value = getTimeFromISO(event.startTime as string);
      extendOriginal.value = event;
    }

    const startTime = (tms: CalendarTimestamp) => {
      const mouse = toTime(tms);

      if (dragEvent.value && dragTime.value === null) {
        const start = getTimeFromISO(dragEvent.value.startTime); // setting up to drag a whole event... ?
        dragTime.value = mouse - start;
      } else {
        createStart.value = roundTime(mouse);
        const booking = new Booking();
        booking.startTime = formatISO(new Date(createStart.value));
        booking.endTime = formatISO(new Date(createStart.value + 15 * 60 * 1000)); // add 15 minutes to start time

        tempEvent.value = booking;
        editingEvent.value = tempEvent.value;
      }
    };

    const mouseMove = (tms: CalendarTimestamp): void => {
      const mouse = toTime(tms);

      if (dragEvent.value && dragTime.value !== null) {
        dragged.value = true;
        // dragging whole existing event
        const startString = dragEvent.value.startTime;
        const endString = dragEvent.value.endTime;

        const [start, end] = getTimesFromISO(startString, endString);

        const duration = end - start;
        const newStartTime = mouse - dragTime.value;

        const newStart = roundTime(newStartTime);
        const newEnd = newStart + duration;

        dragEvent.value.startTime = newStart;
        dragEvent.value.endTime = newEnd;
      } else if ((createEvent.value || editingEvent.value) && createStart.value !== null) {
        dragged.value = true;
        // new event so we are just extendined the bottom
        const mouseRounded = roundTime(mouse, false);
        const min = Math.min(mouseRounded, createStart.value);
        let max = Math.max(mouseRounded, createStart.value);

        if (max == min) {
          max = min + 15 * 60 * 1000;
        }

        if (editingEvent.value) {
          editingEvent.value.startTime = min;
          editingEvent.value.endTime = max;
        }
      }
    };

    const endDrag = async (): Promise<void> => {
      if (dragEvent.value) {
        editingEvent.value = dragEvent.value as Booking;
      }

      if (extendOriginal.value && dragged.value) {
        await editingEvent.value?.update();
        await resetTempObjects();
      } else if (dragEvent.value && dragged.value) {
        await dragEvent.value?.update();
        await resetTempObjects();
      } else {
        addingBooking.value = true;
      }

      dragTime.value = null;

      createEvent.value = null;
      createStart.value = null;
      extendOriginal.value = null;
      dragged.value = false;
    };

    onMounted(async () => {
      await setPractitioner();
      await fetchBookings();
    });

    const nowY = computed(() => {
      if (!calendar.value.times) {
        return;
      }
      return calendar.value ? calendar.value.timeToY(calendar.value.times.now) + 'px' : '-10px';
    });

    const handleMouseLeave = () => {
      if (!addingBooking.value) {
        resetTempObjects(true);
      }
    };

    const scheduleCreated = async (): Promise<void> => {
      await fetchBookings();
      editingSchedule.value = undefined;
    };

    const deleteBooking = async (booking: Booking): Promise<void> => {
      const confirm = await confirmation('Are you sure you want to delete this booking?', {
        confirmType: 'warn',
      });

      if (confirm) {
        await booking.directDelete();

        await fetchBookings();
      }
    };

    watch(selectedPractitioner, async () => {
      await fetchBookings();
    });

    return {
      value,

      dragEvent,
      dragStart,
      createEvent,
      createStart,
      extendOriginal,
      bookings,
      calendar,
      addingBooking,

      getEventColor,
      showLabel,
      intervalStyle,
      startDrag,
      startTime,
      selectedPractitioner,
      practitioner,
      nowY,
      mouseMove,
      endDrag,
      events,
      extendBottom,
      tempEvent,
      resetTempObjects,
      fetchBookings,
      dragged,
      editingEvent,
      handleMouseLeave,
      range,
      updateRange,

      addingSchedule,
      addSiteVisit,
      editingSchedule,
      addingScheduleDate,
      scheduleCreated,
      routeNames,
      deleteBooking,
    };
  },
});
