












import CreateScheduleForm from './CreateScheduleForm.vue';
import { PropType, computed, defineComponent } from '@/plugins/composition';
import { Schedule } from '@/models';

export default defineComponent({
  components: { CreateScheduleForm },
  name: 'CreateScheduleDialog',
  component: {
    CreateScheduleForm,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    date: {
      type: String,
      required: false,
    },
    schedule: {
      type: Object as PropType<Schedule>,
      required: false,
    },
  },

  setup(props, { emit }) {
    const dialog = computed({
      get: () => props.value,
      set: (value) => emit('input', value),
    });

    const cancel = () => {
      dialog.value = false;
      emit('cancel');
    };

    const submit = () => {
      dialog.value = false;
      emit('submit');
    };

    return {
      dialog,
      cancel,
      submit,
    };
  },
});
