import { CalendarTimestamp } from 'vuetify';

function toTime(tms: CalendarTimestamp): number {
  return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
}

function roundTime(time: number, down = true): number {
  const roundTo = 15; // minutes
  const roundDownTime = roundTo * 60 * 1000;

  return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
}

const intervalStyle = (label: { minute: number }): { [key: string]: string } => {
  const styles = {
    borderTopColor: '#2abf64',
    borderTopWidth: '1px',
    borderTopStyle: 'dashed',
  };

  if (label.minute == 0) {
    styles.borderTopStyle = 'solid';
  }

  return styles;
};

const showLabel = (label: { minute: number }): boolean => {
  return label.minute == 0;
};

export { toTime, roundTime, intervalStyle, showLabel };
