














import { BookingReason } from '@/models';
import { PropType, computed, defineComponent, onMounted, ref } from '@/plugins/composition';
import { useLoader } from '@/composables/useLoader';

export default defineComponent({
  name: 'BookingReasonDropdown',
  props: {
    value: {
      type: Object as PropType<BookingReason>,
      default: null,
    },
    category: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const items = ref<BookingReason[]>([]);

    const { loading, withLoader } = useLoader();

    const reason = computed({
      get: () => props.value,
      set: (item: BookingReason) => emit('input', item),
    });

    const fetchReaons = async () =>
      withLoader(async () => {
        items.value = await BookingReason.builder()
          .when(!!props.category, (query) => query.where('category', props.category))
          .getAll();
      });

    onMounted(() => fetchReaons());

    return {
      items,
      reason,
      loading,
    };
  },
});
