
















import CreateBookingForm from './CreateBookingForm.vue';
import DateTimePicker from '@/components/inputs/DateTimePicker.vue';
import DateTimeRangePicker from '@/components/inputs/DateTimeRangePicker.vue';
import { Booking } from '@/models';
import { PropType, computed, defineComponent, ref } from '@/plugins/composition';

export default defineComponent({
  components: { CreateBookingForm, DateTimePicker, DateTimeRangePicker },
  name: 'CreateBookingDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    booking: {
      type: Object as PropType<Booking>,
      required: false,
      default: () => new Booking(),
    },
  },

  setup(props, { emit }) {
    const type = ref(0);
    const types = ref(['patient', 'site']);

    const dialog = computed({
      get: () => props.value,
      set: (value) => emit('input', value),
    });

    const cancel = () => {
      dialog.value = false;
      emit('cancel');
    };

    const submit = () => {
      dialog.value = false;
      emit('submit');
    };

    return {
      type,
      dialog,
      types,
      cancel,
      submit,
    };
  },
});
