



















import { Practitioner, Site } from '@/models';

import { PropType, computed, defineComponent, ref, watch } from '@/plugins/composition';
import { debouncedSearch } from '@/utils/inputSearch';

export default defineComponent({
  name: 'PractitionerSelectInput',

  props: {
    value: {
      type: Object as PropType<Practitioner | null>,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Select a Practitioner',
    },
    site: {
      type: Object as PropType<Site>,
      required: false,
    },
  },

  setup(props, { emit }) {
    const practitioners = ref<Practitioner[]>([]);
    const search = ref(null);
    const loading = ref(false);

    const practitioner = computed({
      get: () => props.value,
      set: (value) => emit('input', value),
    });

    const fetchPractitioners = async (): Promise<void> => {
      loading.value = true;

      const target = props.site ? props.site.practitioners() : Practitioner.builder();

      practitioners.value = await target.where('name', search.value).getAll();
      loading.value = false;
    };

    const searchPractitioners = debouncedSearch(() => fetchPractitioners());

    watch(search, () => searchPractitioners(), { immediate: true });

    watch(
      () => props.value,
      (value) => {
        if (value instanceof Practitioner) {
          practitioners.value.push(value);
        }
      }
    );

    return {
      practitioners,
      search,
      practitioner,
      loading,
    };
  },
});
