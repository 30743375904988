


















import DateTimeRangePicker from '@/components/inputs/DateTimeRangePicker.vue';
import PractitionerSelectInput from '@/components/inputs/PractitionerSelectInput.vue';
import SiteSelectInput from '@/components/inputs/SiteSelectInput.vue';
import { Practitioner, Schedule, Site } from '@/models';
import { PropType, computed, defineComponent, onMounted, ref, toRef } from '@/plugins/composition';
import { addHours } from 'date-fns';
import { confirmation } from '@/domains/shared/components/confirm-dialog/confirm';
import { formatDate } from '@/utils';
import { formatISO } from 'date-fns/fp';
import { setHours } from 'date-fns/esm';
import { storage } from '@/main';

export default defineComponent({
  name: 'CreateScheduleForm',
  components: {
    PractitionerSelectInput,
    DateTimeRangePicker,
    SiteSelectInput,
  },
  props: {
    date: {
      type: [String, Date] as PropType<string | Date>,
      default: () => formatDate(new Date(), 'yyyy-MM-dd'),
    },
    schedule: {
      type: Object as PropType<Schedule>,
      required: false,
      default: () => {
        const schedule = new Schedule();
        schedule.startTime = formatISO(new Date());
        schedule.endTime = formatISO(addHours(new Date(), 9));
        return schedule;
      },
    },
  },

  setup(props, { emit }) {
    const valid = ref(false);
    const site = ref<Site | null>();
    const practitioner = ref<Practitioner | null>(null);
    const loading = ref(false);

    const scheduleObject = toRef(props, 'schedule');

    onMounted(async () => {
      practitioner.value = await storage.auth.practitioner();
      site.value = scheduleObject.value.site;

      if (!scheduleObject.value.exists()) {
        scheduleObject.value.startTime = formatISO(
          setHours(new Date(props.date ?? formatDate(new Date(), 'yyyy-MM-dd')), 8)
        );
        scheduleObject.value.endTime = formatISO(
          setHours(new Date(props.date ?? formatDate(new Date(), 'yyyy-MM-dd')), 17)
        );
      }
    });

    const submit = async () => {
      loading.value = true;
      if (scheduleObject.value.exists()) {
        await update();
      } else {
        await create();
      }

      loading.value = false;
      emit('input');
    };

    const destroy = async (): Promise<void> => {
      const confirm = await confirmation('Are you sure you want to delete this schedule?', {
        confirmType: 'warn',
      });

      if (confirm) {
        scheduleObject.value.delete();
        emit('input');
      }
    };

    const update = async () => {
      scheduleObject.value.site = site.value as Site;
      scheduleObject.value.update();
    };

    const create = async () => {
      if (!practitioner.value || !site.value) {
        return;
      }
      const schedule = scheduleObject.value;
      schedule.practitioner = practitioner.value;
      schedule.site = site.value;
      await practitioner.value.schedules().create(schedule.serialise());
    };

    const dateRange = computed({
      get: () => ({
        start: scheduleObject.value.startTime ?? null,
        end: scheduleObject.value.endTime ?? null,
      }),
      set: ({ start, end }) => {
        scheduleObject.value.startTime = start;
        scheduleObject.value.endTime = end;
      },
    });

    return {
      practitioner,
      valid,
      dateRange,
      loading,
      scheduleObject,
      site,
      submit,
      destroy,
    };
  },
});
