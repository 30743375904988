

























import BookingReasonDropdown from '@/components/inputs/BookingReasonDropdown.vue';
import DateTimeRangePicker from '@/components/inputs/DateTimeRangePicker.vue';
import PatientSelectInput from '@/components/inputs/PatientSelectInput.vue';
import PractitionerSelectInput from '@/components/inputs/PractitionerSelectInput.vue';
import SiteSelectInput from '@/components/inputs/SiteSelectInput.vue';
import { Booking, BookingReason, Patient, Practitioner, Site } from '@/models';
import { PropType, computed, defineComponent, onMounted, ref, toRef } from '@/plugins/composition';
import { confirmation } from '@/domains/shared/components/confirm-dialog/confirm';
import { storage } from '@/main';

export default defineComponent({
  components: {
    PatientSelectInput,
    SiteSelectInput,
    DateTimeRangePicker,
    PractitionerSelectInput,
    BookingReasonDropdown,
  },
  name: 'CreateBookingForm',

  props: {
    type: {
      type: String,
      required: true,
      default: 'patient',
    },
    booking: {
      type: Object as PropType<Booking>,
      required: false,
      default: () => new Booking(),
    },
  },

  setup(props, { emit }) {
    const valid = ref(false);
    const patient = ref<Patient | null>(null);
    const practitioner = ref<Practitioner | null>(null);
    const site = ref<Site | null>(null);
    const loading = ref(false);
    const bookingReason = ref<BookingReason | null>(null);

    const bookingObject = toRef(props, 'booking');

    onMounted(async () => {
      practitioner.value = await storage.auth.practitioner();
      patient.value = bookingObject.value.patient;
      site.value = bookingObject.value.site;
      bookingReason.value = bookingObject.value.site ? bookingObject.value.bookingReasons[0] : null;
    });

    const dateRange = computed({
      get: () => ({
        start: bookingObject.value.startTime ?? null,
        end: bookingObject.value.endTime ?? null,
      }),
      set: ({ start, end }) => {
        bookingObject.value.startTime = start;
        bookingObject.value.endTime = end;
      },
    });

    const destroy = async (): Promise<void> => {
      const confirm = await confirmation('Are you sure you want to delete this booking?', {
        confirmType: 'warn',
      });

      if (confirm) {
        bookingObject.value.delete();
        emit('input');
      }
    };

    const submit = async () => {
      loading.value = true;
      if (bookingObject.value.exists()) {
        await update();
      } else {
        await create();
      }

      loading.value = false;
      emit('input');
    };

    const update = async () => {
      if (bookingObject.value.patient) {
        bookingObject.value.patient = patient.value;
        bookingObject.value.update();
      } else {
        bookingObject.value.site = site.value;
        bookingObject.value.tempData.bookingReasons = bookingReason.value ? [bookingReason.value.data] : [];
        bookingObject.value.update();
      }
    };

    const create = async () => {
      if (props.type === 'patient') {
        return createForPatient();
      } else if (props.type === 'site') {
        return createForSite();
      }
    };

    const createForSite = async () => {
      if (!site.value || !practitioner.value) {
        return;
      }
      const booking = bookingObject.value;
      booking.practitioner = practitioner.value;
      const data = booking.serialise();
      await site.value.bookings().create({
        ...data,
        booking_reasons: bookingReason.value ? [bookingReason.value.uuid] : [],
      });
    };

    const createForPatient = async () => {
      if (!patient.value || !practitioner.value) {
        return;
      }
      const booking = bookingObject.value;
      booking.practitioner = practitioner.value;
      const data = booking.serialise();
      await patient.value.bookings().create(data);
    };

    return {
      valid,
      patient,
      site,
      bookingObject,
      dateRange,
      practitioner,
      submit,
      loading,
      destroy,
      bookingReason,
    };
  },
});
