






import DailyScheduleCalendar from './components/DailyScheduleCalendar.vue';
import { defineComponent } from '@/plugins/composition';

export default defineComponent({
  components: { DailyScheduleCalendar },
  name: 'MyDailySchedulePage',
});
