


















































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Client, Site, isEqual } from '@/models';
import { Prop, VModel, Watch } from 'vue-property-decorator';
import { debouncedSearch } from '@/utils/inputSearch';

@Component({
  name: 'SiteSelectInput',
})
export default class extends Vue {
  @VModel({ type: [Site, Object] }) site!: Site | null;
  @Prop({ type: String, default: 'Select a Site' }) readonly label!: string;
  @Prop({ type: Client }) readonly client!: Client | null;
  @Prop({ type: Boolean, default: false }) readonly scoped!: boolean;

  @Prop({ type: Boolean, default: false }) readonly allowAdd!: boolean;
  @Prop({ type: Boolean, default: false }) readonly allowEdit!: boolean;
  @Prop({ type: String, default: null }) readonly target!: string;
  @Prop({ type: Boolean, default: false }) readonly required!: boolean;

  items: Site[] = [];

  search = '';

  loading = false;
  editing: Client | null = null;

  rules = this.required ? [(v: Site | null): string | boolean => !!v || 'Site is required'] : [];

  searchSites = debouncedSearch(() => this.fetchSites());

  get disabled(): boolean {
    return this.scoped && this.client == null;
  }

  async setSite(site: string | Site): Promise<void> {
    if (!site) {
      this.$emit('input', null);
      return;
    }

    if (site instanceof Site) {
      this.$emit('input', site);
    }
  }

  async fetchSites(): Promise<void> {
    this.loading = true;
    if (this.client?.exists() || this.scoped) {
      this.fetchClientSites();
    } else {
      this.fetchAllSites();
    }
    this.loading = false;
  }

  async fetchAllSites(): Promise<void> {
    this.items = await Site.where('name', this.search).with('client').get();
  }

  async fetchClientSites(): Promise<void> {
    if (this.client?.exists()) {
      this.items = await this.client.sites().with('client').where('name', this.search).get();
    }
  }

  async edit(index: number, item: Client): Promise<void> {
    if (!this.editing) {
      this.editing = item;
    } else {
      this.editing = null;
      await item.update();
      this.searchSites();
    }
  }

  async create(name: string): Promise<void> {
    if (this.client) {
      const response = await this.client.sites().create({ name });
      this.$emit('input', response);
    }

    await this.fetchClientSites();
  }

  @Watch('search')
  async onSearchChanged(): Promise<void> {
    this.searchSites();
  }

  @Watch('client')
  async onClientChanged(newVal: Client | null, oldVal: Client | null): Promise<void> {
    this.fetchClientSites();
    if (!isEqual(newVal, oldVal)) {
      this.$emit('input', null);
    }
  }
}
